module.exports = `<div class="container">
                <p class="ml-0">Soracom Mobileサービス契約約款</p>
                <p class="ml-1">第1章 総則</p>
                <p class="ml-2">第1.1条 約款の適用</p>
                <p class="ml-2">第1.2条 約款の変更</p>
                <p class="ml-2">第1.3条 用語の定義</p>
                <p class="ml-1">第2章 サービス</p>
                <p class="ml-2">第2.1条 サービスの概要</p>
                <p class="ml-2">第2.2条 サービスの提供区域</p>
                <p class="ml-1">第3章 本契約の締結</p>
                <p class="ml-2">第3.1条 申込の方法</p>
                <p class="ml-2">第3.2条 申込の承諾</p>
                <p class="ml-2">第3.3条 契約の効力発生</p>
                <p class="ml-2">第3.4条 動作環境</p>
                <p class="ml-2">第3.5条 アカウント</p>
                <p class="ml-1">第4章 契約者の変更等</p>
                <p class="ml-2">第4.1条 契約者の氏名等の変更の届出</p>
                <p class="ml-2">第4.2条 名義変更(契約上の地位の移転又は承継)</p>
                <p class="ml-1">第5章 利用の制限、中断、中止及び停止等</p>
                <p class="ml-2">第5.1条 利用の制限</p>
                <p class="ml-2">第5.2条 通信の切断</p>
                <p class="ml-2">第5.3条 サービスの提供中止</p>
                <p class="ml-2">第5.4条 サービスの廃止</p>
                <p class="ml-1">第6章 本契約の解除</p>
                <p class="ml-2">第6.1条 当社が行う契約の解除</p>
                <p class="ml-1">第7章 責務等</p>
                <p class="ml-2">第7.1条 守秘義務</p>
                <p class="ml-2">第7.2条 信用の維持</p>
                <p class="ml-2">第7.3条 必要事項の通知</p>
                <p class="ml-1">第8章 eSIM及び端末</p>
                <p class="ml-2">第8.1条 eSIMプロファイル</p>
                <p class="ml-2">第8.2条 端末機器</p>
                <p class="ml-1">第9章 通信</p>
                <p class="ml-2">第9.1条 データ量の測定</p>
                <p class="ml-1">第10章 SORACOMシステムの利用</p>
                <p class="ml-2">第10.1条 SORACOMシステムの提供</p>
                <p class="ml-2">第10.2条 本アプリケーション等への接続</p>
                <p class="ml-2">第10.3条 SORACOMシステムの利用条件</p>
                <p class="ml-1">第11章 禁止行為</p>
                <p class="ml-2">第11.1条 禁止行為</p>
                <p class="ml-1">第12章 料金等</p>
                <p class="ml-2">第12.1条 サービス利用料</p>
                <p class="ml-2">第12.2条 サービス利用料の支払方法</p>
                <p class="ml-1">第13章 保守</p>
                <p class="ml-2">第13.2条 修理又は復旧</p>
                <p class="ml-1">第14章 知的財産</p>
                <p class="ml-2">第14.1条 知的財産権</p>
                <p class="ml-1">第15章 保証の否認</p>
                <p class="ml-2">第15.1条 保証の否認</p>
                <p class="ml-1">第16章 補償</p>
                <p class="ml-2">第16.1条 補償</p>
                <p class="ml-2">第16.2条 責任の制限</p>
                <p class="ml-1">第17章 雑則</p>
                <p class="ml-2">第17.1条 約款の掲示</p>
                <p class="ml-2">第17.2条 プライバシーポリシー</p>
                <p class="ml-2">第17.3条 不可抗力</p>
                <p class="ml-2">第17.4条 反社会的勢力の排除</p>
                <p class="ml-2">第17.5条 分離可能性</p>
                <p class="ml-2">第17.6条 仲裁</p>
                <p class="ml-2">第17.7条 準拠法</p>
                <p class="ml-1">料金表</p>
                <h2>総則</h2>
                <h3>約款の適用</h3>
                <p>
                    SORACOM CORPORATION, LTD.(以下、｢当社｣といいます。)は、Soracom
                    Mobileサービスに関する本契約約款(以下、｢本約款｣といいます。)を定め、本約款に基づき締結されるSoracom
                    Mobileサービス契約(以下、「本契約」といいます。)に基づき、Soracom
                    Mobileサービスを提供します。
                </p>
                <h3>約款の変更</h3>
                <p>
                    当社は、本約款を変更することがあります。かかる変更を実施する場合、当社は、当社のウェブサイトへの掲示又は当社が別途定める方法で契約者に対して変更内容を告知するものとします。当該告知が行なわれた後に契約者がSoracom
                    Mobileサービスを利用した場合には、契約者は、かかる変更に同意したものとみなし、当社は、変更後の約款に規定される料金その他の提供条件を適用します。
                </p>
                <h3>用語の定義</h3>
                <p>本約款においては、次の用語はそれぞれ次の意味で使用します。</p>
                <table>
                    <thead>
                        <tr>
                            <th>用語</th>
                            <th>用語の意味</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>電気通信設備</th>
                            <td>電気通信を行うための機械、器具、伝送路その他の電気的設備</td>
                        </tr>
                        <tr>
                            <th>電気通信回線</th>
                            <td>送信の場所と受信の場所との間を接続する伝送路設備</td>
                        </tr>
                        <tr>
                            <th>電気通信サービス</th>
                            <td>
                                電気通信設備を使用して他人の通信を媒介すること、その他電気通信設備を他人の通信の用に供すること
                            </td>
                        </tr>
                        <tr>
                            <th>本アプリケーション</th>
                            <td>
                                契約者がSoracom
                                Mobileサービスの提供を受けるために端末にインストールする当社指定のアプリケーション
                            </td>
                        </tr>
                        <tr>
                            <th>eSIMプロファイル</th>
                            <td>
                                GSMA準拠のリモートSIMプロビジョニングに対応した、端末組込みSIM(eSIM)用通信事業者プロファイルを指します。
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h2>サービス</h2>
                <h3>サービスの概要</h3>
                <p>
                    Soracom
                    Mobileサービスは、セルラー回線を利用したモバイルデータ通信専用であり、電話番号を用いた通話やSMS、MMSなどその他のサービスは利用できません。サービスの種類については末尾の料金表(以下、「本料金表」といいます。)に記載のとおりです。
                </p>
                <h3>サービスの提供区域</h3>
                <ol>
                    <li>
                        Soracom
                        Mobileサービスの提供区域は、別途本アプリケーション若しくはウェブサイトに掲示した国又は地域（以下、「サービスエリア」といいます。）とします。ただし、その提供区域内であっても電波の伝わりにくいところでは、Soracom
                        Mobileサービスを利用することができない場合があります。
                    </li>
                    <li>
                        契約者は、法規制、規則ないし命令等（以下、「規制等」といいます。）によって、Soracom
                        Mobileサービスがサービスエリアの一部の国又は地域で利用できなくなる場合があることを確認します。当社は、規制等の存在を確認する合理的な努力を行うものとし、その裁量により、かかる規制等に対応するために合理的に必要であると考える範囲で、いつでも、Soracom
                        Mobileサービスを制限、変更、中断若しくは廃止し、又はサービス提供のための追加の要件を契約者に課すことができるものとします。
                    </li>
                    <li>
                        当社は、いかなる場合であっても、規制等に反する態様でSoracom
                        Mobileサービスを提供することを求められないものとし、規制等により当社がSoracom
                        Mobileサービスを提供できないことは、当社の本契約への違反とはみなされないものとします。
                    </li>
                </ol>
                <h2>本契約の締結</h2>

                <h3>申込の方法</h3>
                <p>
                    Soracom
                    Mobileサービスの利用申込者(以下、｢申込者｣といいます。)は、本約款を承認した上で、本アプリケーションを利用して申込(以下、｢申込｣といいます。)を行うものとします。申込者は
                    成年である必要があります。
                </p>

                <h3>申込の承諾</h3>
                <ol>
                    <li>
                        当社は、申込者に対して、申込者がSoracom
                        Mobileサービスの提供に関し必要な情報の提出を求めることがあります。
                    </li>
                    <li>
                        当社は、次に掲げる事由に該当すると判断する場合を除き、当該申込を承諾します。
                    </li>
                    <li>申込者が本約款上の債務の履行を怠るおそれがあるとき</li>
                    <li>
                        申込者に対するSoracom
                        Mobileサービスの提供により、当社又は他の契約者の信用又は利益を損なうおそれがあるとき
                    </li>
                    <li>
                        申込者に対するSoracom
                        Mobileサービスの提供により、当社若しくは第三者の知的財産権、所有権その他の権利を害するおそれがあるとき。
                    </li>
                    <li>申込者が未成年であるとき。</li>
                    <li>
                        申込者に当社との信頼関係を著しく損なう行為があったとき又は申込者若しくはその役員等が反社会的勢力に該当するとき。
                    </li>
                    <li>申込者が第5.1条(利用の制限)第3項各号の事由に該当するとき。</li>
                    <li>当社が申込者との契約を解除したことがあるとき。</li>
                    <li>申込者が当社に対し虚偽の事実を通知したとき。</li>
                    <li>
                        申込に際し、申込者が支払手段として正当に使用することができないクレジットカードを指定したとき。
                    </li>
                    <li>申込者がSoracom Mobileサービスを適切に利用する意思が無いとき。</li>
                    <li>
                        当社は、前項の規定にかかわらず、通信の取扱上余裕がないときは、その申込みの承諾を延期することがあります。
                    </li>
                </ol>

                <h3>契約の効力発生</h3>
                <p>
                    本契約は、申込を当社が第3.2条(申込の承諾)に基づき承諾した日に申込者と当社の間で効力を生じるものとします(以下、効力が発生した日を｢効力発生日｣、効力発生日以降の申込者を契約者と称するものとします。)。
                </p>

                <h3>動作環境</h3>
                <p>
                    Soracom
                    Mobileサービスは組込み型SIMを搭載したSIMロックフリー（SIMロックのかかっていない）端末で、一定のOSバージョン環境でのみ使用できます。利用可能端末についての詳しい情報は本アプリケーション又は当社ウェブサイトをご確認ください。当社は、上記以外の環境でSoracom
                    Mobileサービスを使用した場合に関しては一切動作保証はせず、また発生する損害に対しての責任を負いません。
                </p>

                <h3>アカウント</h3>
                <ol>
                    <li>
                        Soracom Mobileサービスを利用するためには、契約者は、有効なApple
                        ID（以下、「Apple ID」といいます）に関連づけたアカウント(以下、「Soracom
                        Mobileアカウント」といいます。)を作成しなければなりません。本約款で明示的に認められている場合を除き、契約者は一つのApple
                        IDにつき、一つのSoracom Mobileアカウントのみ作成することができます。
                    </li>
                    <li>
                        当社は、契約者に対し、前項に基づき作成されるSoracom
                        Mobileアカウントに当社が提供するシステムにログインするためのIDであるログインID(以下｢本ログインID｣といいます。)及びログインパスワード(以下｢本ログインパスワード｣といいます。)を付与します。
                    </li>
                    <li>
                        契約者は、自己の責任において本ログインID及び本ログインパスワードを管理するものとし、本ログインID及び本ログインパスワードを第三者に貸与、譲渡若しくは使用許諾又は第三者の利益のために使用してはならないものとします。また、契約者は、SORACOM
                        Mobileアカウントの不正使用若しくはそのおそれを認識した場合又はSoracom
                        Mobileアカウント情報の紛失若しくは盗難があった場合、直ちに当社にその旨通知するものとします。
                    </li>
                    <li>
                        契約者は、自らのSoracom
                        Mobileアカウントに基づき生じるあらゆる事象につき、かかる事象が契約者、契約者の役員若しくは従業員、又は第三者による不正使用若しくは誤使用のいずれによるものかを問わず一切の責任を負うものとし、当社は何らの責任も負担しないものとします。また、かかるSoracom
                        Mobileアカウントの使用に基づき当社に損害が発生した場合、契約者は当社に対し、当該損害を賠償しなければならないものとます。
                    </li>
                    <li>
                        当社は、Soracom
                        Mobileアカウント登録後30日以内にeSIMが登録されていない場合、当該アカウントおよびその関連データを削除する権利を有します。
                    </li>
                    <li>
                        当社は、最終のアクティビティーから18ヶ月間一切ログインおよび使用されなかったSoracom
                        Mobileアカウントについて、それらを削除する権利を有します。
                    </li>
                </ol>
                <h2>契約者の変更等</h2>

                <h3>契約者の氏名等の変更の届出</h3>
                <ol>
                    <li>
                        契約者は、氏名、Apple
                        ID、当社に届け出たクレジットカードその他の当社が指定する事項に変更があったとき又はかかる変更の予定を認識したときは、当社に対し、直ちに当該変更の内容について通知するものとします。
                    </li>
                    <li>
                        前項の届出があったときは、当社は、その届出のあった事実を証明する書類を提示していただくことがあります。
                    </li>
                    <li>
                        契約者が第1項に規定する変更を当社に届け出ないときは、当社が契約者から届出を受けている氏名、名称、若しくはApple
                        IDへの通知を行った場合は、当該通知は契約者に対して行われたものとみなします。
                    </li>
                </ol>

                <h3>契約上の地位の移転又は承継</h3>
                <ol>
                    <li>
                        契約者はSoracom
                        Mobileサービス又はSORACOMシステム（10.1条に定義する）の提供を受ける権利を第三者に譲渡、承継、再使用許諾、質権その他担保に供する等の行為をすることはできません。
                    </li>
                    <li>
                        当社は、(a)合併、買収、又は当社資産の全部若しくは大部分の売却に関連する場合、又は(b)当社のいずれかの関連会社を相手先とする場合、若しくは企業組織再編成の一環として行う場合には、契約者の同意なくして、本契約上の地位を第三者に譲渡することができるものとします。かかる譲渡がなされた時点で、譲受人は、本契約の当事者として当社に代わるものとみなされ、当社は本契約に基づき履行すべきすべての義務及び責務から完全に免れるものとします。前記に従うことを条件として、本契約は、両当事者ならびに各々の許可された承継人及び譲受人を拘束し、それらの者の利益のために効力を有するものとします。
                    </li>
                </ol>
                <h2>利用の制限、中断、中止及び停止等</h2>

                <h3>利用の制限</h3>
                <ol>
                    <li>
                        当社は、天災、事変その他の非常事態が発生し、又は発生するおそれがあるときは、災害の予防若しくは救援、交通、通信若しくは電力の供給の確保、又は秩序の維持その他の公共の利益のために必要となる通信を優先的に取り扱うため、Soracom
                        Mobileサービスの利用を制限することができます。
                    </li>
                    <li>
                        当社は、帯域を継続的かつ大量に占有する通信手順又はアプリケーションを用いて行われる電気通信を検知し、その電気通信に割り当てられる帯域を制御すること等により、その電気通信の速度や通信量を制御することができます。
                    </li>
                    <li>
                        当社は、契約者が次に掲げる事由に該当するときは、当該契約者に対するSoracom
                        Mobileサービスの利用を停止又は制限することができます。

                        <ol>
                            <li>料金支払その他の本契約に基づく債務について、履行を遅滞したとき</li>
                            <li>契約者が当社に対し虚偽の事実を通知したとき</li>
                            <li>契約者が第11.1条(禁止行為)の規定に違反したと当社が認めたとき。</li>
                            <li>第3.2条(申込の承諾)第2項に定める申込の拒絶事由に該当するとき。</li>
                            <li>
                                契約者が指定したクレジットカードを使用することができなくなったとき。
                            </li>
                        </ol>
                    </li>
                </ol>

                <h3>通信の切断</h3>
                <p>当社は、Soracom Mobileサービスの通信に関して、次の措置をとることがあります。</p>
                <ol>
                    <li>
                        契約者回線がデータ通信を行うことができる状態(かかる状態を以下｢セッション｣といいます。)が長時間継続されたと当社が認める場合において、その通信を切断することがあります。
                    </li>
                    <li>
                        同一セッション内に大量の通信があったと当社が認める場合において、その通信を切断することがあります。
                    </li>
                </ol>

                <h3>サービスの提供中止</h3>
                <p>当社は、次の場合にはSoracom Mobileサービスの提供を中止することができます。</p>

                <ol>
                    <li>
                        当社の電気通信設備又はシステムの保守上又は工事のためにやむを得ないとき。
                    </li>
                    <li>
                        電気通信事業者(以下「通信キャリア」といいます。)が当社への通信サービスの提供を停止するとき。
                    </li>
                    <li>クラウド提供業者が当社へのクラウドサービスの提供を停止するとき。</li>
                    <li>規制等により中止する必要が生じたとき。</li>
                    <li>
                        当社は、前項の規定によりSoracom
                        Mobileサービスの利用を中止するときは、あらかじめそのことを当社の本アプリケーション若しくはウェブサイト等において掲示します。ただし、緊急やむを得ない場合は、この限りではありません。
                    </li>
                </ol>

                <h3>サービスの廃止</h3>
                <p>
                    当社は、技術上、業務の遂行上又は規制等によりやむを得ない場合は、Soracom
                    Mobileサービスの全部又は一部を廃止することがあります。
                </p>

                <h2>本契約の解除</h2>

                <h3>当社が行う契約の解除</h3>

                <p>
                    当社は、契約者が次に掲げる事由に該当するときは、本契約を解除することができます。その場合、当社は、合理的な時期に契約者にその旨を通知します。
                </p>

                <ol>
                    <li>
                        第5.1条(利用の制限)の規定によりSoracom
                        Mobileサービスの提供を停止又は制限された契約者が、なお当該利用停止の原因事実を解消しないとき。
                    </li>
                    <li>
                        第5.1条(利用の制限)各号の規定のいずれかに該当する場合で、その事実が当社の業務の遂行に特に著しい支障を及ぼすと当社が判断したとき。
                    </li>
                    <li>
                        当社と通信キャリアとの間の当社への携帯電話サービスの提供に関する契約が通信キャリアによって解除されたとき。
                    </li>
                    <li>
                        当社とクラウド提供業者との間の当社へのクラウドサービスの提供に関する契約がクラウド提供業者によって解除されたとき。
                    </li>
                    <li>
                        第5.4条(サービスの廃止)の規定によりSoracom
                        Mobileサービスの全部が廃止されたときは、当該廃止の日に本契約が解除されたものとします。
                    </li>
                </ol>
                <h2>責務等</h2>

                <h3>守秘義務</h3>
                <p>
                    当社及び申込者(本契約の締結後は契約者。本条において同じとします。)は、第3.1条(申込の方法)に基づく申込以降、相手方の技術上、経営上の情報及び相手方のその他一般に公表していない一切の情報に関する秘密を厳守し、これをSoracom
                    Mobileサービスの提供又は使用の目的以外に使用しないこととします。ただし、法令又は裁判所、監督官庁その他当社又は申込者を規制する権限を有する公的機関の裁判、規則又は命令に従い必要な範囲において当該情報を開示することができます。なお、本条は本契約の締結に至らなかった場合又は本契約が解除又はその他の理由により終了した場合であっても有効に存続するものとします。
                </p>

                <h3>信用の維持</h3>
                <p>
                    契約者は、Soracom
                    Mobileサービスの使用にあたり、当社の信用を損なう行為を行わないように努めるものとします。
                </p>

                <h3>必要事項の通知</h3>

                <p>
                    当社は、次の各号に定める事項のいずれかが発生した場合、当該事実を発生後速やかに契約者に対して通知することとします。
                </p>

                <ol>
                    <li>電気通信事業の休止若しくは廃止</li>
                    <li>電気通信事業を行うために必要となる登録、届出等の監督官庁による取消し</li>
                    <li>第5.1条(利用の制限)に基づくSoracom Mobileサービスの利用制限</li>
                    <li>
                        Soracom
                        Mobileサービスの提供条件に影響を及ぼす電気通信設備の変更、増設又は廃止
                    </li>
                    <li>当社の解散</li>
                </ol>

                <h2>eSIM及び端末</h2>

                <h3>eSIMプロファイル</h3>

                <ol>
                    <li>
                        当社は、契約者が当社から取得したeSIMの保管・管理、使用において一切の責任を負いません。契約者は、eSIMの使用に対して全責任を負うものとし、いかなる状況においても、契約者のeSIMまたはSoracom
                        Mobileサービスの誤用または不適合・不適切な使用によって、契約者又は第三者に生じた損失、費用、または損害について、当社は一切責任を負いません。
                    </li>
                    <li>
                        eSIMプロファイルの利用は、Soracom
                        Mobileサービスの利用可能期間（詳細は本料金表参照）が終了してから24時間経過後に終了するものとします。また、効力発生日から起算して6カ月を経過した場合も、eSIMプロファイルの利用は終了するものとします。
                    </li>
                    <li>eSIMの再発行はできません。</li>
                    <li>
                        契約者のSoracom
                        Mobileアカウントに登録されているeSIMプロファイルは譲渡できません。
                    </li>
                    <li>
                        契約者が端末の機種変更や故障修理を行う際であっても、新たな端末へのeSIMプロファイルの再発行又は移行を行うことはできません。
                    </li>
                </ol>

                <h3>端末機器</h3>
                <ol>
                    <li>
                        契約者は関係法令が定める技術基準に適合し、Soracom
                        Mobileサービスが対応する端末を利用することとします。
                    </li>
                    <li>
                        当社は、契約者に対し、契約者回線に接続されている自営端末設備に異常がある場合その他電気通信サービスの円滑な提供に支障がある場合、電波発射の停止命令があった場合等はその自営端末設備の使用を停止させることができます。
                    </li>
                </ol>

                <h2>通信</h2>

                <h3>データ量の測定</h3>
                <p>
                    Soracom
                    Mobileサービスについては、当社は、契約者に対して契約者回線と当社の間において伝送されるデータ量を当社又は当社の指定する者の機器により測定します。この場合において、回線の故障等発信者又は着信者の責任によらない理由により、データ(当社が定めるものを除きます。)が通信の相手先(その通信が相互接続点への通信であるときは、その相互接続点を通信の相手先とします。)に到達しなかった場合には、そのデータについては、データ量の測定から除きます。
                </p>

                <h2>SORACOMシステムの利用</h2>

                <h3>SORACOMシステムの提供</h3>
                <p>
                    当社は、契約者に対し、当社がSoracom
                    Mobileサービスの利便性向上のために有用であると判断した範囲で、コンソールシステム(以下、「SORACOMシステム」といいます。)を、本アプリケーション若しくはWEBサイト(以下、総称して｢本アプリケーション等｣といいます。)を通じて提供します。
                </p>

                <h3>本アプリケーション等への接続</h3>
                <p>
                    契約者が本アプリケーション等へ接続する場合、契約者が自らの費用と責任で行うものとします。本アプリケーション等への接続中、回線・無線LANの環境等の不具合で接続が中断した場合であっても当社は一切の責任を負いません。
                </p>

                <h3>SORACOMシステムの利用条件</h3>
                <ol>
                    <li>
                        契約者は、法令等を遵守し、善良な管理者の注意をもって通常の用法に従って、Soracom
                        Mobileサービスを使用するためにのみSORACOMシステムを利用するものとします。
                    </li>
                    <li>
                        当社は、契約者に事前に連絡することなく、SORACOMシステムにより提供する情報(以下、｢SORACOM提供情報｣といいます。)の内容その他のSORACOMシステムの内容を変更することができます。当該変更が重要なものである場合は、当社は、契約者に対して事前に通知します。
                    </li>
                </ol>

                <h2>禁止行為</h2>
                <h3>禁止行為</h3>
                <ol>
                    <li>
                        本約款の他の規定において定めるものに加え、契約者は、次の各号に掲げる行為を行うことはできません。

                        <ol>
                            <li>関連法令が定める技術基準に適合しない端末を利用すること</li>
                            <li>Soracom Mobileサービスが対応しない端末を利用すること</li>
                            <li>
                                契約者がSoracom
                                Mobileサービスを利用する国において法令、規則等により禁じられている行為（例えば、他国へのデータ通信及びパーマネントローミングが禁じられている場合は、それらの行為）
                            </li>
                            <li>
                                当社所定の基準を超過したトラヒック量を継続的に発生させることにより、Soracom
                                Mobileサービス用に使用される設備又はシステムに過大な負荷を生じさせる行為
                            </li>
                            <li>
                                児童買春、児童ポルノを閲覧又は取得するため、迷惑メール又はSMS等の送信その他当社が不適切と判断する目的においてSoracom
                                Mobileサービスを利用する行為
                            </li>
                            <li>
                                SORACOMシステムの利用者資格を含むSORACOM提供情報の全部若しくは一部の第三者への譲渡、承継、転貸、質権その他担保に供する等の行為
                            </li>
                            <li>
                                第三者の使用に供するためにSORACOMシステムの利用者資格を含むSORACOM提供情報の全部若しくは一部を複製すること。
                            </li>
                            <li>
                                第三者(契約者顧客を除きます。)にSORACOMシステム及びSORACOM提供情報を取扱わせること。
                            </li>
                            <li>SORACOM提供情報を改変又は改竄すること。</li>
                            <li>
                                第三者が提供する商品又はサービスに対してSORACOM提供情報を利用すること。
                            </li>
                            <li>
                                当社又は第三者の知的財産権を侵害する商品又はサービスに対してSORACOM提供情報を利用すること。
                            </li>
                            <li>SORACOM提供情報を基にして知的財産権を出願すること。</li>
                            <li>
                                不正なアクセス、コンピューターウィルス等を用いてSORACOM提供情報を格納するサーバーに対して攻撃を行うこと。
                            </li>
                            <li>
                                SORACOMシステムに対し、リバースエンジニアリング、逆コンパイル、逆アセンブルその他一切の解析を行うこと。
                            </li>
                            <li>前各号の行為を第三者に行わせること。</li>
                        </ol>
                    </li>
                    <li>
                        契約者は、Soracom
                        Mobileサービスが、軍事、航空、宇宙、原子力、生命維持及び医療に関連する用途での使用に適合するように設計され、又はそのような用途への適合性が保証されたものではないことを確認します。かかる用途での使用は推奨されないものであり、契約者がかかる用途での使用をする場合は自らの責任で行うものとし、かかる用途での使用に起因ないし関連して当社が損害を被った場合には、当該損害の一切を当社に対して補償するものとします。
                    </li>
                </ol>

                <h2>料金等</h2>

                <h3>サービス利用料</h3>
                <p>
                    当社が提供するSoracom Mobileサービスの料金(以下、「Soracom
                    Mobileサービス料金」といいます。)の額は、本料金表に定めるところによります。
                </p>

                <h3>サービス利用料の支払方法</h3>
                <p>
                    契約者は、本料金表に規定するSoracom
                    Mobileサービス料金を、本アプリケーションの定める手続き・方法に従って支払うものとします。
                </p>

                <h2>保守</h2>

                <h3>修理又は復旧</h3>
                <p>
                    当社は、当社の設置した電気通信設備又はシステムが故障し又は滅失した場合は、速やかに修理し又は復旧するものとします。ただし、24時間未満の修理又は復旧を保証するものではありません。
                </p>

                <h2>知的財産</h2>

                <h3>知的財産権</h3>
                <p>
                    Soracom
                    Mobileサービス、SORACOMシステム、SORACOM提供情報及びこれらに付帯するサービスに関する特許権、実用新案権、意匠権、著作権、ノウハウその他一切の知的財産及び実証実験のデータその他の記録は当社に帰属するものであり、本約款、Soracom
                    Mobileサービス、SORACOMシステム又はこれらに付帯するサービス提供の過程での当社による契約者に対する情報の開示は、明示、黙示を問わず、いかなる意味においても、当社の特許権、実用新案権、意匠権、著作権、ノウハウその他一切の知的財産に基づく実施権その他のいかなる権利の許諾、付与、又は譲渡を構成するものではありません。
                </p>

                <h2>保証の否認</h2>
                <h3>保証の否認</h3>
                <p>
                    契約者は、Soracom
                    Mobileサービス、SORACOMシステム、SORACOM提供情報及びこれらに付帯するサービスは現状のままで提供されることに合意するものとします。当社は、提供されるSoracom
                    Mobileサービス、SORACOMシステム、SORACOM提供情報及びこれらに付帯するサービスに関し、品質、サービスが中断されないこと、誤りがないこと、第三者の権利を侵害しないこと、特定目的への適合性の保証を含め、明示であると黙示であるとを問わず、いかなる種類の表明も保証も行いません。
                </p>

                <h2>補償</h2>
                <h3>補償</h3>
                <p>
                    当社及び契約者は、本約款に定める義務に違反したことにより相手方に損害を与えた場合には、本約款に別途定める場合を除き、当該義務違反により相手方が被った損害を賠償する責任を負うものとします。
                </p>

                <h3>責任の制限</h3>
                <ol>
                    <li>
                        当社は、第三者（通信キャリア又はクラウド提供業者を含みます。）の責めに帰すべき事由によってSoracom
                        Mobileサービス、SORACOMシステム、SORACOM提供情報及びこれらに付帯するサービスが利用不能となった場合、責任を負わないものとします。
                    </li>
                    <li>
                        当社は、Soracom
                        Mobileサービスを提供すべき場合において、当社の責に帰すべき事由によりSoracom
                        Mobileサービスが、当社が利用不能となったことを認識してから 24
                        時間以上の時間(以下｢利用不能時間｣といいます。)、継続して完全に利用不能となった場合において、契約者から請求があった場合に限り、当社は、契約者に対し、その請求に基づき、利用不能時間を24で除した日数(小数点以下の端数は、切り捨てます。)に応じたSoracom
                        Mobileサービス料金の額を、契約者の損害とみなしその額に限って賠償します。ただし、契約者が利用不能となったことを知った日から
                        3
                        ヶ月を経過する日までに当該請求をしなかったときは、契約者は、その権利を失うものとします。ただし、当社の故意又は重大な過失による場合はこの限りではありません。
                    </li>
                    <li>
                        前項に規定する利用不能以外の場合において、当社が契約者に対して損害賠償責任を負うときは、事由の如何を問わず、契約者が損害の発生時点から直近３カ月の間に当社に対して支払ったSoracom
                        Mobileサービス料金の額を上限とします。また、いかなる場合であっても、契約者の、逸失利益、信用・評判の毀損、費用削減の不達成、データの消失その他の間接・付随的損害について、それらの損害が予見可能であったか否かに関わらず、当社は責任を負わないものとします。ただし、当社の故意又は重大な過失による場合はこの限りではありません。
                    </li>
                    <li>
                        前各項の規定にかかわらず、通信キャリア又はクラウド提供業者の帰責事由によるSoracom
                        Mobileサービスの利用不能の場合には、当社は、当該通信キャリア又はクラウド提供業者から受領した損害賠償額を限度として契約者に生じた損害(但し、現実に発生した通常損害に限られ、逸失利益、間接損害は含みません。)につき責任を負います。
                    </li>
                    <li>
                        当社は、Soracom
                        Mobileサービスの提供が行われなかったことによる逸失利益及び契約者の顧客、契約者顧客その他の第三者から契約者への問合せ対応、故障修理の請求等その他の苦情の受付又は対応等に要した費用等について一切責任を負わないものとし、契約者はかかる逸失利益又は費用等を当社へ請求しないものとします。
                    </li>
                    <li>
                        電気通信設備又はシステムの修理、復旧等に当たって、その電気通信設備又はシステムに記憶されている内容等が変化又は消失することがあります。当社はこれにより損害を与えた場合に、それが当社の故意又は重大な過失により生じたものであるときを除き、その損害を賠償しません。
                    </li>
                </ol>

                <h2>雑則</h2>

                <h3>約款の掲示</h3>
                <p>当社は、最新の本約款を当社のウェブサイトにおいて掲示することとします。</p>

                <h3>プライバシーポリシー</h3>
                <p>
                    当社は、契約者に関する個人情報の取扱いに関する方針(以下「プライバシーポリシー」といいます。)を定め、これを本アプリケーション又は当社のウェブサイトにおいて公表します。
                </p>

                <h3>不可抗力</h3>
                <p>
                    本契約当事者の過失によらずに発生した限りにおいて、本契約における義務の不履行、履行遅滞は、本契約の違反にならないものとします。その事由は、公権力の作為又は不作為、火災、ストライキ、ロックアウトその他の労働争議、洪水、戦争、暴動、地震、自然災害、公共の交通または通信手段の故障、コンピューターの誤作動・故障を含むものとしますが、それらに限定されないものとします。本条は、上記の事由によって被った損害について、第三者に損害賠償を請求する当事者の権利
                    を何ら制限するものではありません。
                </p>

                <h3>反社会的勢力の排除</h3>
                <ol>
                    <li>
                        当社及び契約者は、自己が反社会的勢力(｢企業が反社会的勢力による被害を防止するための指針(平成19年6月19日犯罪対策閣僚会議幹事会申合せ)｣において、暴力、威力又は詐欺的手法を駆使して経済的利益を追求する集団又は個人である旨定められている｢反社会的勢力｣、以下同じとします。)又は次のいずれかに該当する者(以下併せて｢反社会的勢力等｣といいます。)に該当しないことを表明及び保証し、現在及び将来において反社会的勢力又は次の事項に該当しないことを確約するものとします。
                    </li>
                    <li>
                        役員等(役員のほか、支配人、営業所の代表者その他いかなる名称によるかを問わず役員と同等以上の職権又は支配力を有するものをいい、非常勤の者を含みます。)に、暴力団員による不当な行為の防止等に関する法律(平成3年法律第77号。その後の改正を含みます。)第2条第6号に規定する暴力団員(以下｢暴力団員｣といいます。)又は同条第2号に規定する暴力団(以下｢暴力団｣といいます。)と関係を持ちながら、その組織の威力を背景として同条第1号に規定する暴力的不法行為等を行なうおそれがある者(以下｢暴力団関係者｣といいます。)がいること。
                    </li>
                    <li>
                        暴力団、暴力団員又は暴力団関係者(以下これら三者を｢暴力団等｣と総称します。)が経営に関与していること。
                    </li>
                    <li>暴力団等から名目を問わず資金提供、出資などの便益を受けていること。</li>
                    <li>暴力団等に対し名目を問わず資金の供給などの便益を供与していること。</li>
                    <li>
                        反社会的勢力との間に、利用、協力、交際など社会的に非難されるべき関係を有していること。
                    </li>
                    <li>
                        当社又は契約者が、相手方が第1項の規定に反すると疑う事実のあるときは、相手方に対し当該事項に関する報告を求めることができ、報告を求められた相手方は指定された期日までに報告書を提出するものとします。
                    </li>
                    <li>
                        当社又は契約者は、相手方が次の各号のいずれかに該当した場合は、即時本契約を解除し、解除によって生じた損害を相手方に請求することができるものとします。
                    </li>
                    <li>
                        第1項の表明、保証又は確約に反し、又は反すると疑うに足る相当の理由があるとき。
                    </li>
                    <li>
                        第2項の規定に違反して報告書を提出せず、又は虚偽の記載をした報告書を提出したとき。
                    </li>
                </ol>

                <h3>分離可能性</h3>
                <p>
                    本約款のいずれかの条項が何らかの理由により無効又は執行不能とされた場合であっても、本約款の他の条項が無効又は執行不能となるものではなく、また、かかる場合には、当該規定は、有効かつ執行可能となるために必要な限度において限定的に解釈されるものとします。
                </p>

                <h3>仲裁</h3>
                <p>
                    本契約に起因し又は関連する一切の紛争（本契約の存在、有効性、終了についての紛争を含みます）については、ロンドン国際仲裁裁判所（London
                    Court of International
                    Arbitration）（以下「LCIA」といいます。）にて終局的に解決されるものとします。仲裁には、仲裁の申立て時点において有効なLCIAの仲裁規則を適用するものとします。契約者及び当社は、それぞれ1名ずつの仲裁人を選任し、LCIAがもう1名の仲裁人を、仲裁人3名からなる合議体の議長として選任するものとします。契約者又は当社が、仲裁の申立てをしてから又は仲裁の申立てがあったことの通知を受領してから30日以内に仲裁人を選任しなかった場合は、当該仲裁人についてもLCIAが選任するものとします。仲裁手続は、英国ロンドンにて開催されるものとし、使用される言語は英語とします。
                </p>

                <h3>準拠法</h3>
                <p>
                    本約款の成立、効力、解釈及び履行については、イギリス法に準拠するものとします。
                </p>

                <h2 class="no-increment">料金表</h2>

                <p>
                    契約者は、下表の料金を支払うことにより、下表に定めるデータ容量のデータ通信を、定められた地域・日数の範囲で（各地域の具体的な国名ないし範囲は、本アプリケーションにおいて表示します）行うことができます。なお、データ容量及び日数が残っている状態でも同一地域のデータプランを購入することができ、その場合、当該データプラン分のデータ容量と日数が、残っていたデータ容量及び日数に加算されます。ただし、当初の利用可能期間開始日から起算して、６カ月目以降は、残っているデータ容量及び日数に関わらず利用することができないものとします。６カ月以内に必ずデータを使い切るようご留意ください。
                </p>

                <h3 class="no-increment">地域別データプラン</h3>

                <h4>ヨーロッパ</h4>

                <table>
                    <thead>
                        <tr>
                            <th>データ容量</th>
                            <th>日数</th>
                            <th>税抜き料金 (USドル)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1GB</td>
                            <td>30日（720時間）</td>
                            <td>5.89</td>
                        </tr>
                        <tr>
                            <td>3GB</td>
                            <td>30日（720時間）</td>
                            <td>14.99</td>
                        </tr>
                        <tr>
                            <td>5GB</td>
                            <td>30日（720時間）</td>
                            <td>23.99</td>
                        </tr>
                        <tr>
                            <td>10GB</td>
                            <td>30日（720時間）</td>
                            <td>42.99</td>
                        </tr>
                    </tbody>
                </table>

                <h4>北米</h4>

                <table>
                    <thead>
                        <tr>
                            <th>データ容量</th>
                            <th>日数</th>
                            <th>税抜き料金 (USドル)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                10.99
                            </td>
                        </tr>
                        <tr>
                            <td>3GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                28.99
                            </td>
                        </tr>
                        <tr>
                            <td>5GB</td>
                            <td>30日（720時間）</td>
                            <td>44.99</td>
                        </tr>
                        <tr>
                            <td>10GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                97.99
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h4>オセアニア</h4>

                <table>
                    <thead>
                        <tr>
                            <th>データ容量</th>
                            <th>日数</th>
                            <th>税抜き料金 (USドル)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                12.99
                            </td>
                        </tr>
                        <tr>
                            <td>3GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                32.99
                            </td>
                        </tr>
                        <tr>
                            <td>5GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                52.99
                            </td>
                        </tr>
                        <tr>
                            <td>10GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                97.99
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h4>アジアパシフィック(11カ国)</h4>
                <p>
                    アメリカ、ベトナム、オーストラリア、台湾、香港、シンガポール、インドネシア、マカオ、カンボジア、ラオス、スリランカで利用できます。
                </p>

                <table>
                    <thead>
                        <tr>
                            <th>データ容量</th>
                            <th>日数</th>
                            <th>税抜き料金 (USドル)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                6.99
                            </td>
                        </tr>
                        <tr>
                            <td>3GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                19.99
                            </td>
                        </tr>
                        <tr>
                            <td>5GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                30.99
                            </td>
                        </tr>
                        <tr>
                            <td>10GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                54.99
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h4>アジアパシフィック (15カ国)</h4>
                <p>
                    アメリカ、ベトナム、オーストラリア、台湾、香港、シンガポール、インドネシア、マカオ、カンボジア、ラオス、スリランカ、タイ、韓国、インド、日本で利用できます。
                </p>

                <table>
                    <thead>
                        <tr>
                            <th>データ容量</th>
                            <th>日数</th>
                            <th>税抜き料金 (USドル)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                10.99
                            </td>
                        </tr>
                        <tr>
                            <td>3GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                31.99
                            </td>
                        </tr>
                        <tr>
                            <td>5GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                46.99
                            </td>
                        </tr>
                        <tr>
                            <td>10GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                89.99
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h4>アジアパシフィック (18カ国)</h4>
                <p>
                    アメリカ、ベトナム、オーストラリア、台湾、香港、シンガポール、インドネシア、マカオ、カンボジア、ラオス、スリランカ、タイ、韓国、インド、日本、中国本土、ニュージーランド、バングラディシュで利用できます。
                </p>

                <table>
                    <thead>
                        <tr>
                            <th>データ容量</th>
                            <th>日数</th>
                            <th>税抜き料金 (USドル)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                17.99
                            </td>
                        </tr>
                        <tr>
                            <td>3GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                49.99
                            </td>
                        </tr>
                        <tr>
                            <td>5GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                74.99
                            </td>
                        </tr>
                        <tr>
                            <td>10GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                145.99
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h4>国別プラン(日本)</h4>

                <table>
                    <thead>
                        <tr>
                            <th>データ容量</th>
                            <th>日数</th>
                            <th>税抜き料金 (USドル)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                6.89
                            </td>
                        </tr>
                        <tr>
                            <td>3GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                17.79
                            </td>
                        </tr>
                        <tr>
                            <td>5GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                28.79
                            </td>
                        </tr>
                        <tr>
                            <td>10GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                54.99
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h4>
                    国別プラン(アメリカ、ベトナム、オーストラリア、台湾、香港、シンガポール、インドネシア、マカウ、カンボジア、ラオス、スリランカのいずれか一か国で使用できます。)
                </h4>

                <table>
                    <thead>
                        <tr>
                            <th>データ容量</th>
                            <th>日数</th>
                            <th>税抜き料金 (USドル)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                5.89
                            </td>
                        </tr>
                        <tr>
                            <td>3GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                16.49
                            </td>
                        </tr>
                        <tr>
                            <td>5GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                26.99
                            </td>
                        </tr>
                        <tr>
                            <td>10GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                52.99
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h4>国別プラン(タイ、韓国、インドのいずれか一か国で使用できます。)</h4>

                <table>
                    <thead>
                        <tr>
                            <th>データ容量</th>
                            <th>日数</th>
                            <th>税抜き料金 (USドル)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                9.49
                            </td>
                        </tr>
                        <tr>
                            <td>3GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                27.49
                            </td>
                        </tr>
                        <tr>
                            <td>5GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                45.49
                            </td>
                        </tr>
                        <tr>
                            <td>10GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                87.99
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h4>国別プラン(中国本土、バングラディシュのいずれか一か国で使用できます。)</h4>

                <table>
                    <thead>
                        <tr>
                            <th>データ容量</th>
                            <th>日数</th>
                            <th>税抜き料金 (USドル)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                14.49
                            </td>
                        </tr>
                        <tr>
                            <td>3GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                43.49
                            </td>
                        </tr>
                        <tr>
                            <td>5GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                69.99
                            </td>
                        </tr>
                        <tr>
                            <td>10GB</td>
                            <td>30日（720時間）</td>
                            <td>
                                139.99
                            </td>
                        </tr>
                    </tbody>
                </table>
`
