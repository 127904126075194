module.exports = `
                <p class="ml-0">TABLE OF CONTENTS</p>
                <p class="ml-0">
                    This Table of Contents is not part of this Agreement and is inserted for
                    convenience only.
                </p>
                <p class="ml-1">ARTICLE 1. GENERAL PROVISIONS</p>
                <p class="ml-2">1.1 Application of General Terms and Conditions</p>
                <p class="ml-2">1.2 Change of General Terms</p>
                <p class="ml-2">1.3 Definitions</p>
                <p class="ml-1">ARTICLE 2. SERVICE</p>
                <p class="ml-2">2.1 Service</p>
                <p class="ml-2">2.2 Service Area</p>
                <p class="ml-1">ARTICLE 3. EXECUTION OF AGREEMENT</p>
                <p class="ml-2">3.1 Method of Application</p>
                <p class="ml-2">3.2 Acceptance of Application</p>
                <p class="ml-2">3.3 Effectuation of Agreement</p>
                <p class="ml-2">3.4 Compatibility</p>
                <p class="ml-2">3.5 Account</p>
                <p class="ml-2">3.6 Right of Cancellation</p>
                <p class="ml-1">ARTICLE 4. CHANGE OF SUBSCRIBER’S NAME AND ASSIGNMENT</p>
                <p class="ml-2">4.1 Notification of Change of Subscriber’s Name</p>
                <p class="ml-2">4.2 Assignment or Transfer of Agreement</p>
                <p class="ml-1">ARTICLE 5. USE RESTRICTION</p>
                <p class="ml-2">5.1 Use Restriction</p>
                <p class="ml-2">5.2 Disconnection of Transmission</p>
                <p class="ml-2">5.3 Suspension of Service</p>
                <p class="ml-2">5.4 Discontinuation of Service</p>
                <p class="ml-1">ARTICLE 6. TERMINATION OF THIS AGREEMENT</p>
                <p class="ml-2">6.1 Termination by SORACOM</p>
                <p class="ml-1">ARTICLE 7. RESPONSIBILITY</p>
                <p class="ml-2">7.1 Confidentiality</p>
                <p class="ml-2">7.2 Maintenance of Confidence</p>
                <p class="ml-2">7.3 Notification of Necessary Information</p>
                <p class="ml-1">ARTICLE 8. eSIM AND TERMINAL DEVICE</p>
                <p class="ml-2">8.1 eSIM Profile</p>
                <p class="ml-2">8.2 Terminal Device</p>
                <p class="ml-1">ARTICLE 9. TELECOMMUNICATION</p>
                <p class="ml-2">9.1 Measurement of Amount of Data</p>
                <p class="ml-1">ARTICLE 10. USE OF SORACOM SYSTEM</p>
                <p class="ml-2">10.1 Providing SORACOM System</p>
                <p class="ml-2">10.2 Connecting to App, etc.</p>
                <p class="ml-2">10.3 Use Conditions for SORACOM System</p>
                <p class="ml-2">10.4 Quality and Service</p>
                <p class="ml-1">ARTICLE 11. PROHIBITED ACTS</p>
                <p class="ml-2">11.1 Prohibited Acts</p>
                <p class="ml-1">ARTICLE 12. SERVICE FEE</p>
                <p class="ml-2">12.1 Service Fee</p>
                <p class="ml-2">12.2 Method of Payment</p>
                <p class="ml-1">ARTICLE 13. MAINTENANCE</p>
                <p class="ml-2">13.1 Repair, Recovery and Service</p>
                <p class="ml-1">ARTICLE 14. INTELLECTUAL PROPERTY</p>
                <p class="ml-2">14.1 Intellectual Property</p>
                <p class="ml-1">ARTICLE 15. DISCLAIMER OF WARRANTIES</p>
                <p class="ml-2">15.1 Disclaimer of Warranties</p>
                <p class="ml-1">ARTICLE 16. INDEMNIFICATION</p>
                <p class="ml-2">16.1 Indemnification</p>
                <p class="ml-2">16.2 Limitation of Liability</p>
                <p class="ml-1">ARTICLE 17. PROCESSING OF PERSONAL DATA</p>
                <p class="ml-1">ARTICLE 18. MISCELLANEOUS</p>
                <p class="ml-2">18.1 Posting this Agreement</p>
                <p class="ml-2">18.2 Force Majeure</p>
                <p class="ml-2">18.3 Terminating and rejecting relations with Anti-social Forces</p>
                <p class="ml-2">18.4 Severability</p>
                <p class="ml-2">18.5 Dispute Resolution</p>
                <p class="ml-2">18.6 Governing law</p>
                <p class="ml-1">Schedules</p>
                <p class="ml-2">Schedule 1 Service Fee</p>
                <p class="ml-2">
                    Schedule 2 Model withdrawal form for Subscribers that are consumers
                </p>
                <p>General Terms and Conditions for Soracom Mobile Service</p>

                <h2>GENERAL PROVISIONS</h2>
                <h3>Application of General Terms and Conditions</h3>
                <p>
                    These general terms and conditions ( the “General Terms”) govern the use of
                    Soracom Mobile Service. SORACOM CORPORATION, LTD, whose registered office is at
                    16 Great Queen Street, Covent Garden, London, United Kingdom, WC2B 5AH,
                    registered under company number 12311887 (“SORACOM”) will provide the Soracom
                    Mobile Service to the Subscriber (defined in Section 3.3 below) according to the
                    Soracom Mobile Service agreement concluded under the General Terms (such
                    agreement, the “Agreement”).
                </p>

                <h3>Change of General Terms</h3>
                <p>
                    SORACOM may change the General Terms from time to time. If SORACOM changes the
                    General Terms, SORACOM will notify the Subscriber of such change by announcing
                    the change on the website of SORACOM or such other method as may be separately
                    specified by SORACOM. SORACOM will send a notice to the Subscriber 30 days prior
                    to any significant changes to the General Terms unless the change solely
                    benefits the Subscriber. The Subscriber may terminate the Agreement with 14
                    days' notice during such 30 days' notice period. If the Subscriber uses the
                    Soracom Mobile Service after such notice, the Subscriber shall be deemed to have
                    consented to such change to the General Terms, SORACOM will apply the Service
                    Fee (defined in Section 12.1 below) and other service provision provided in such
                    changed General Terms.
                </p>

                <h3>Definitions</h3>
                <p>
                    The following terms when used in the General Terms shall have the meanings set
                    forth below.
                </p>

                <ul>
                    <li>
                        “App” shall mean application software that enables Subscribers to use the
                        Soracom Mobile Global Service by installing on their terminal devices.
                    </li>
                    <li>
                        “Electronic Communication Network” shall mean the transmission line
                        connecting the data transmission place and the data receiving place.
                    </li>
                    <li>
                        “eSIM” or “eSIM profile” shall mean a carrier profile for embedded
                        Subscriber Identity Module (eSIM) that is conformed to the GSM Association
                        standard.
                    </li>
                    <li>
                        “Telecommunications Facility” shall mean the machine, equipment,
                        transmission path or other electrical facilities for telecommunication.
                    </li>
                    <li>
                        “Telecommunication Service” shall mean relaying other’s communications with
                        the use of Telecommunications Facilities, or providing Telecommunications
                        Facilities to be used for others' communications
                    </li>
                </ul>

                <h2>SERVICE</h2>
                <h3>Service</h3>
                <p>
                    Soracom Mobile Service is dedicated to mobile data communication utilizing
                    cellular communication network and doesn’t cover other services, such as voice
                    calls, SMS or MMS. The plans offered by SORACOM are specified in the Schedule
                </p>

                <h3>Service Area</h3>
                <ol>
                    <li>
                        SORACOM shall provide the Soracom Mobile Service within the area designated
                        on the App or the website of SORACOM (the “Service Area”), provided, that,
                        the Service Area may be different if stated otherwise as specified by
                        SORACOM separately. However, within the Service Area, you may not use the
                        Soracom Mobile Service in places where transmissions are difficult to send
                        or receive.
                    </li>
                    <li>
                        The parties of this Agreement acknowledge that there may be countries or
                        locations within which SORACOM may be restricted from providing the Soracom
                        Mobile Service due to applicable laws, regulations, decisions, rules or
                        orders (“Restrictions”) despite the list of the Service Area. SORACOM will
                        use reasonable efforts to monitor whether there are any such Restrictions.
                        SORACOM may in its sole discretion and at any time, suspend, discontinue,
                        limit, or modify the Soracom Mobile Service or impose additional
                        requirements on the provision of the Soracom Mobile Service, as may be
                        reasonably required to comply with any such Restrictions.
                    </li>
                    <li>
                        In no event will SORACOM be required to provide the Soracom Mobile Service
                        in countries or locations, or in a manner that would be in violation of the
                        Restrictions and its failure to provide the Soracom Mobile Service due to
                        the Restrictions will not be deemed to be a breach of its obligations under
                        this Agreement.
                    </li>
                    <li>
                        In the event that any Restriction, or any change in applicable law,
                        regulation, decision, rule or order materially or adversely affects the
                        delivery of the Soracom Mobile Service (including the economic viability
                        thereof), SORACOM will notify Subscribers and the parties will negotiate in
                        good faith regarding changes to this Agreement. If the parties cannot reach
                        agreement within 30 days after notification from SORACOM requesting
                        negotiation, SORACOM may terminate the Agreement upon 30 days’ written
                        notice to the Subscriber.
                    </li>
                </ol>

                <h2>EXECUTION OF AGREEMENT</h2>
                <h3>Method of Application</h3>
                <p>
                    Applicants for the Soracom Mobile Service (each such applicant, an “Applicant”)
                    shall apply for the Soracom Mobile Service via the App in accordance with the
                    procedure specified by SORACOM after the Applicant agrees to the General Terms
                    (such application, the “Application”). Applicant must.
                </p>

                <h3>Acceptance of Application</h3>
                <ol>
                    <li>
                        SORACOM may request the Applicant to submit information necessary for
                        SORACOM to determine the eligibility of the Applicant. In such case, the
                        Applicant shall promptly submit such information in writing.
                    </li>
                    <li>
                        SORACOM will consent to the Application, except if SORACOM determines that:
                    </li>
                    <li>there is an undue risk that the Applicant may breach the General Terms;</li>
                    <li>
                        provision of the Soracom Mobile Service to the Applicant carries an undue
                        risk of damage to the confidence or profit of SORACOM or other Subscriber
                        (defined in Section 3.3);
                    </li>
                    <li>
                        provision of the Soracom Mobile Service to the Applicant carries an undue
                        risk of harm to the intellectual property rights, property rights and other
                        rights of SORACOM or a third party;
                    </li>
                    <li>the Applicant is under the age of .</li>
                    <li>
                        the Applicant damages the relationship of mutual trust between the Applicant
                        and SORACOM, or the Applicant or representative thereof is an Anti-Social
                        Force (defined in Section 18.3) ;
                    </li>
                    <li>the Applicant falls under any of the items of Section 5.1(c) below;</li>
                    <li>
                        SORACOM has terminated the agreement between SORACOM and the Applicant for
                        cause;
                    </li>
                    <li>the Applicant provides any false information to SORACOM;</li>
                    <li>
                        the Applicant designates an unavailable credit card as the method of
                        payment; or
                    </li>
                    <li>
                        the Applicant does not intend to use the Soracom Mobile Service
                        appropriately.
                    </li>
                    <li>
                        Notwithstanding Section 3.2(b), if SORACOM has no capacity in the
                        communication network, SORACOM may postpone or refuse the Application.
                    </li>
                </ol>

                <h3>Effectuation of Agreement</h3>
                <p>
                    The Agreement shall become effective between the Applicant and SORACOM as of the
                    date that SORACOM consents to the Application in accordance with Section 3.2
                    (the “Effective Date”, and after Effective Date the Applicant shall be referred
                    to as the “Subscriber”).
                </p>

                <h3>Compatibility</h3>
                <p>
                    The Soracom Mobile Service is only available on unlocked devices with eSIM
                    capability running a certain version of Operating System. For the full list of
                    the compatibility devices and OS versions, please check the App or SORACOM’s
                    website. We shall not bear any responsibility in relation to the use of the
                    incompatible devices.
                </p>

                <h3>Account</h3>
                <ol>
                    <li>
                        The Subscriber shall make an account that is associated with an effective
                        Apple ID(the“Apple ID”) to use the Soracom Mobile Service (the “Soracom
                        Mobile Account”). The Subscriber may make only one SORACOM account per Apple
                        ID, unless otherwise expressly provided in the General Terms.
                    </li>
                    <li>
                        SORACOM will provide the Subscriber with a Log-in ID (the “Log-in ID”) and a
                        Log-in password (the “Log-in Password”) to log into the system to use the
                        SORACOM Mobile Account provided by SORACOM.
                    </li>
                    <li>
                        The Subscriber shall manage and protect the Log-in ID and Log-in Password at
                        the Subscriber’s own responsibility, and shall not lend, transfer, or
                        license the Log-in ID and Log-in Password to any third party and shall not
                        use the Log-in ID and Log-in Password for the profit of any third party. The
                        Subscriber shall notify SORACOM immediately in the following cases:
                    </li>
                    <li>
                        The Subscriber discovers or learns of unauthorized use of the SORACOM Mobile
                        Account by a third party or the risk of such unauthorized use; or
                    </li>
                    <li>
                        The Subscriber loses information regarding the SORACOM Mobile Account
                        (including but not limited to the Log-in ID or Log-in Password), or such
                        information is stolen by third party.
                    </li>
                    <li>
                        The Subscriber is responsible for any event arising from the use of the
                        Subscriber’s own SORACOM Mobile Account, whether unauthorized use or misuse
                        by the Subscriber, Subscriber’s officer, Subscriber’s employee, Subscriber's
                        household or third party, and SORACOM shall not bear any responsibility in
                        relation to such matters. If SORACOM suffers any damage arising from such
                        use of the SORACOM Mobile Account, the Subscriber shall compensate SORACOM
                        for such damage.
                    </li>
                    <li>
                        SORACOM reserves the right to remove Soracom Mobile Account and its
                        associated data if no valid eSIM is registered to the Soracom Mobile Account
                        within 30 days after the account has been opened. For the protection of the
                        Subscriber, SORACOM reserves the right to remove Soracom Mobile Account and
                        its associated data that is inactive for over 18 months from their last
                        activity.
                    </li>
                </ol>

                <h3>Right of Cancellation</h3>
                <ol>
                    <li>
                        If the Subscriber is a consumer (i.e. a natural person acting for purposes
                        outside of his/her trade or profession), the Subscriber may cancel an order
                        for 14 days following receipt of order confirmation from SORACOM. The right
                        of cancellation is invoked by sending an e-mail with "Cancellation" stated
                        in the subject line to SORACOM within the period of 14 days. If the
                        Subscriber intends to make use of the right of cancellation under this
                        section, the Subscriber is also free to use the template in Schedule 2.
                    </li>
                    <li>
                        Notwithstanding section 3.6 (a), the Subscriber is obligated to pay the
                        fees, including – but not limited to – user fees and subscription fees, for
                        the days the subscription has been operating and active, if the Subscriber
                        has requested and is granted access to the Soracom Mobile Service or other
                        services of SORACOM during the 14 days period under section 3.6 (a) above.
                    </li>
                </ol>

                <h2>CHANGE OF SUBSCRIBER’S NAME, AND ASSIGNMENT</h2>
                <h3>Notification of Change of Subscriber’s Name</h3>
                <ol>
                    <li>
                        If the Subscriber changes its name, Apple ID, or credit card and any
                        information submitted to SORACOM, or the Subscriber plans to change such
                        information, the Subscriber shall promptly notify SORACOM of the change.
                    </li>
                    <li>
                        If the Subscriber notifies SORACOM of a change pursuant to Section 4.1(a),
                        SORACOM may require the Subscriber to submit documents evidencing the
                        change.
                    </li>
                    <li>
                        If the Subscriber does not notify SORACOM of any changes required under
                        Section 4.1(a), and SORACOM sends a notice to the Apple ID submitted by the
                        Subscriber, such notice shall be deemed delivered to the Subscriber.
                    </li>
                </ol>
                <h3>Assignment or Transfer of Agreement</h3>
                <ol>
                    <li>
                        The Subscriber shall not assign, transfer, sublicense or provide as
                        collateral, or otherwise dispose of the right to receive the Soracom Mobile
                        Service or the SORACOM System (defined in Section 10.1) under the Agreement.
                    </li>
                    <li>
                        SORACOM may assign this Agreement without Subscriber’s consent (a) in
                        connection with a merger, acquisition or sale of all or substantially all of
                        SORACOM’s assets, or (b) to any affiliate or as part of a corporate
                        reorganization; and effective upon such assignment, the assignee is deemed
                        substituted for SORACOM as a party to this Agreement and SORACOM is fully
                        released from all of its obligations and duties to perform under this
                        Agreement. Subject to the foregoing, this Agreement will be binding upon,
                        and inure to the benefit of the parties and their respective permitted
                        successors and assigns.
                    </li>
                </ol>

                <h2>USE RESTRICTION</h2>
                <h3>Use Restriction</h3>

                <ol>
                    <li>
                        If a natural disaster, incident or any other emergency situation occurs or
                        is likely to occur, SORACOM may restrict the use of the Soracom Mobile
                        Service by the Subscriber in order to give priority to communications whose
                        content are necessary for the prevention of or relief from calamities, for
                        the securing of transportation, communications or electric power supply, the
                        maintenance of public order or any other public interest.
                    </li>

                    <li>
                        If SORACOM detects any significant and/or continuous signal or transmission
                        using a communication procedure or application which occupies the
                        communication band used by SORACOM, SORACOM may control the transmission
                        rate and traffic of such signal or transmission by controlling the
                        communication band allocated to such signal or transmission.
                    </li>

                    <li>
                        SORACOM may suspend or limit the use of the Soracom Mobile Service by the
                        Subscriber, if:

                        <ol>
                            <li>
                                the Subscriber delays in performing or fails to perform any payment
                                obligation or any other obligation under the Agreement;
                            </li>
                            <li>the Subscriber gives a false information to SORACOM;</li>
                            <li>SORACOM deems that the Subscriber violates Section 11.1 below;</li>
                            <li>
                                the Subscriber falls under any of the items of Section 3.2(b); or
                            </li>
                            <li>
                                the credit card account designated by the Subscriber is invalid,
                                unavailable or cannot be used or recognized.
                            </li>
                        </ol>
                    </li>
                </ol>

                <h3>Disconnection of Transmission</h3>
                <ol>
                    <li>
                        If any of the following events occur, SORACOM may disconnect the Session
                        (meaning the state of the Subscriber channel which can perform data
                        transmissions, the same shall apply hereinafter) with respect to Soracom
                        Mobile Service transmissions.
                    </li>
                    <li>
                        If SORACOM deems the Session has been data communication enabled for more
                        than 7 days;
                    </li>
                    <li>
                        If SORACOM deems excess data communication (including, but not limited to
                        continuance of same Session for more than 24 hours) has been performed in
                        the same Session;
                    </li>
                    <li>
                        If SORACOM deems there is a risk of disturbances in smooth provision of
                        Soracom Mobile Service caused by the terminal device;
                    </li>
                    <li>
                        If SORACOM receives a suspension order for radio wave transmission from a
                        supervisory authority; or
                    </li>
                    <li>
                        If SORACOM detects security or integrity related events or other similar
                        vulnerabilities critical to the provision of the Soracom Mobile Service.
                    </li>
                </ol>
                <h3>Suspension of Service</h3>
                <p>SORACOM may suspend provision of the Soracom Mobile Service if:</p>

                <ol>
                    <li>
                        there are unavoidable reasons requiring maintenance or construction of
                        SORACOM’s Telecommunications Facility or system;
                    </li>
                    <li>
                        a telecommunication carrier suspends provision of telecommunication services
                        to SORACOM;
                    </li>
                    <li>
                        a cloud service provider suspends provision of cloud services to SORACOM; or
                    </li>
                    <li>
                        it is necessary for the purpose of ensuring compliance with the
                        Restrictions.
                    </li>
                    <li>
                        If SORACOM suspends the provision of the Soracom Mobile Service according to
                        Section 5.3(a), SORACOM will announce such suspension on the App or
                        SORACOM’s website and the Subscriber is notified by e-mail or equivalent in
                        advance except in cases of urgent necessity.
                    </li>
                </ol>
                <h3>Discontinuation of Service</h3>
                <p>
                    If there are unavoidable reasons (including but not limited to technical,
                    operational or regulatory reasons), SORACOM may discontinue provision of the
                    Soracom Mobile Service in whole or part.
                </p>

                <h2>TERMINATION OF THIS AGREEMENT</h2>
                <h3>Termination by SORACOM</h3>
                <p>
                    SORACOM may terminate the Agreement with a notice of a minimum of end of month
                    plus 30 days.
                </p>
                <ol>
                    <li>
                        SORACOM may terminate the Agreement immediately in the following situations:
                    </li>
                    <li>
                        SORACOM has suspended or restricted use of the Soracom Mobile Service to the
                        Subscriber under Section 5.1 (c) and the cause of such suspension or
                        restriction has not been resolved by the Subscriber; or If the Soracom
                        Mobile Service is discontinued according to Section 5.4 above.
                    </li>
                </ol>

                <h2>RESPONSIBILITY</h2>
                <h3>Confidentiality</h3>
                <p>
                    After the Application, SORACOM and the Subscriber (before execution of the
                    Agreement, the Applicant, hereinafter the same shall apply in this Section)
                    shall keep confidential the other party’s technical, management and any other
                    non-public information acquired from the other party, and shall not use such
                    information except for provision or use of the Soracom Mobile Service; provided,
                    however, that SORACOM and the Subscriber may disclose such other party’s
                    information to the extent necessary to comply with any law or direction,
                    regulation or order of a court, supervisory authority or any other public
                    institution authorized to regulate SORACOM or the Subscriber. Even if SORACOM
                    and the Applicant do not enter into the Agreement, or the Agreement is ended by
                    termination or any other reason, this Section shall survive.
                </p>

                <h3>Maintenance of Confidence</h3>
                <p>
                    In using the Soracom Mobile Service, the Subscriber shall not act in any way to
                    lose the confidence of SORACOM.
                </p>

                <h3>Notification of Necessary Information</h3>
                <p>SORACOM shall promptly notify the Subscriber if any of the following occurs.</p>

                <ol>
                    <li>suspension or discontinuation of SORACOM’s telecommunication business;</li>
                    <li>
                        cancellation of registration or filing, etc. necessary for SORACOM’s
                        telecommunications business by supervisory authority;
                    </li>
                    <li>restriction of the Soracom Mobile Service under ARTICLE 5;</li>
                    <li>
                        change, extension or removal of the Telecommunications Facility which may
                        affect the condition of the Soracom Mobile Service; or dissolution of
                        SORACOM.
                    </li>
                </ol>

                <h2>eSIM AND TERMINAL DEVICE</h2>

                <h3>eSIM Profile</h3>
                <ol>
                    <li>
                        The Subscriber shall be fully responsible for the use and the management of
                        the eSIM and under no circumstances shall we be liable for any loss,
                        expenses, or damages. Under no circumstances shall SORACOM be liable for any
                        loss, expenses, or damages incurred or suffered by Subscriber or any third
                        party resulting from the Subscriber's misuse or non-conforming use of the
                        eSIM and/or Soracom Mobile Service.
                    </li>
                    <li>
                        SORACOM may terminate the eSIM profile (i) after a lapse of 24 hours from
                        the expiration of the period Subscribers can use Soracom Mobile Service (see
                        Schedule 1) or (ii) after a lapse of 6-month period commencing on/with the
                        Effective Date.
                    </li>
                    <li>
                        In any event, the eSIM cannot be re-issued and the eSIM registered to the
                        Subscriber’s account is non-transferable. Even where the Subscriber changes
                        or repairs the terminal device used for Soracom Mobile Service, the same
                        applies.
                    </li>
                </ol>
                <h3>Terminal Device</h3>
                <p>
                    The Subscriber shall use the terminal device in compliance with the technical
                    standards established by the applicable laws and regulations equivalent to the
                    Federal Communications Commission (FCC) rules or the CE Marking (CE Mark) for
                    Radio Telecommunications Equipment, and supported by Soracom Mobile Service.
                </p>

                <h2>TELECOMMUNICATION</h2>
                <h3>Measurement of Amount of Data</h3>
                <p>
                    With respect to the Soracom Mobile Service, SORACOM will measure the amount of
                    data transmitted between the Subscriber channel and SORACOM by SORACOM’s or
                    SORACOM’s designee’s equipment. If the data (excluding any data separately
                    specified by SORACOM, if any) does not reach the communication recipient (if the
                    data is sent to a mutual connection point, such mutual connection point is
                    deemed as the communication recipient) due to a fault in the communication
                    channel or any grounds not attributable to the sender or recipient, such data
                    shall be excluded from the amount of data measured for determining the amount to
                    be charged. The Subscriber may check the amount of data use via the App.
                </p>

                <h2>USE OF SORACOM SYSTEM</h2>
                <h3>Providing SORACOM System</h3>
                <p>
                    SORACOM may, in its sole discretion, provide the Subscriber with a console
                    system (the “SORACOM System”) through the App or the SORACOM’s website
                    (collectively the “App, etc.”) for the purpose of improving the convenience of
                    the Soracom Mobile Service .
                </p>

                <h3>Connecting to App, etc.</h3>
                <p>
                    If the Subscriber connects to the App, etc., the Subscriber shall connect at its
                    own expense and responsibility. Even if the connection is interrupted by failure
                    of the telecommunication network or wireless LAN, SORACOM shall not bear any
                    responsibility for such failure or any damages arising from such failure.
                </p>

                <h3>Use Conditions for SORACOM System</h3>
                <ol>
                    <li>
                        The Subscriber shall use the SORACOM System only for use the Soracom Mobile
                        Service in the normal way and in compliance with laws and regulations, as
                        well as by using the due care of a prudent manager.
                    </li>
                    <li>
                        SORACOM may change the information provided by the SORACOM System or the
                        App, etc. (the “SORACOM Information”) and any other contents of the SORACOM
                        System or the App, etc. without prior notification to the Subscriber. If
                        such change is material, as determined by SORACOM, SORACOM shall notify the
                        Subscriber beforehand.
                    </li>
                </ol>

                <h3>Quality and Service</h3>
                <p>
                    The Subscriber's data speed concerns the capacity the Subscriber has as a user
                    of the Soracom Mobile Service. This has an influence on how swiftly the
                    Subscriber can access the App, websites, download data, etc. The speed and
                    capacity of the connection will be affected by the number of simultaneous users,
                    and how many services and applications that are being used simultaneously. This
                    means that the access to the Soracom Mobile Service and related services will be
                    slower, if many users are using the same services at the same time.
                </p>

                <h2>PROHIBITED ACTS</h2>
                <h3>Prohibited Acts</h3>

                <ol>
                    <li>
                        In addition to any other provision of the General Terms, the Subscriber
                        shall not:

                        <ol>
                            <li>
                                use the terminal device in any way that is not in compliance with
                                the technical standards established by the applicable laws and
                                regulations equivalent to the Federal Communications Commission
                                (FCC) rules or the CE Marking (CE Mark) for Radio Telecommunications
                                Equipment;
                            </li>
                            <li>perform any act prohibited by the telecommunication carrier(s);</li>
                            <li>
                                perform any act (including but not limited to prohibition of data
                                transmission to another country or prohibition of permanent roaming)
                                prohibited by the applicable laws and regulations in the countries
                                where the Subscriber uses Soracom Mobile Services;
                            </li>
                            <li>
                                subject the apparatus or system for the Soracom Mobile Service to
                                excessive data loads by generating continuous traffic exceeding the
                                limits specified by SORACOM separately;
                            </li>
                            <li>
                                use the Soracom Mobile Service for criminal offences such as – but
                                not limited to – child prostitution, viewing or acquiring child
                                pornography or sending nuisance message or any other purpose that
                                SORACOM regards as inappropriate;
                            </li>
                            <li>
                                assign, transfer, sublet or provide as collateral, or otherwise
                                dispose of all or part of SORACOM Information;
                            </li>
                            <li>
                                reproduce all or part of SORACOM Information for the use of third
                                party
                            </li>
                            <li>
                                let a third party (excluding Subscriber’s Customers) use SORACOM
                                System and SORACOM Information;
                            </li>
                            <li>modify or tamper with SORACOM Information;</li>
                            <li>
                                use SORACOM Information for any product or service provided by a
                                third party;
                            </li>
                            <li>
                                use SORACOM Information for any product or service that infringes on
                                intellectual property rights of SORACOM or any third party;
                            </li>
                            <li>
                                file application of intellectual property rights based on the
                                SORACOM Information;
                            </li>
                            <li>
                                attack the storage server of SORACOM Information by using
                                unauthorized access or a computer virus;
                            </li>
                            <li>
                                reverse engineer, decompile, reverse assemble or otherwise analyze
                                the SORACOM System; or
                            </li>
                            <li>
                                let a third party perform any of the respective acts of the
                                preceding paragraphs of this Section.
                            </li>
                        </ol>
                    </li>

                    <li>
                        Subscriber acknowledges that neither the Soracom Mobile Service nor eSIM are
                        designed, authorized or warranted to be suitable for use in the following
                        applications and/or equipments: military, air craft, space, nuclear, life
                        support and medical. Inclusion and/or use in the above mentioned
                        applications/equipments is at the Subscriber’s own risk and is not
                        recommended. Subscriber shall indemnify and hold SORACOM harmless from and
                        against any and all losses, damages, liabilities and expenses which the
                        SORACOM may suffer or incur as a result or as a consequence of, arising out
                        of or in connection with such inclusion and/or use in the above mentioned
                        applications/equipments.
                    </li>
                </ol>

                <h2>SERVICE FEE</h2>
                <h3>Service Fee</h3>
                <p>
                    The rate of the service fee for use of the Soracom Mobile Service (the “Service
                    Fee”) shall be as provided in Schedule 1.
                </p>

                <h3>Method of Payment</h3>
                <p>
                    The Subscriber shall pay the Service Fee and any other fees to be paid to
                    SORACOM under this Agreement with Apple Pay or credit card (VISA, MasterCard and
                    AMEX (American Express)).
                </p>

                <h2>MAINTENANCE</h2>
                <h3>Repair, Recovery and Service</h3>

                <ol>
                    <li>
                        If the telecommunication equipment or system installed by SORACOM breaks
                        down or is lost, SORACOM shall repair or replace such equipment or system
                        promptly. Provided, however, that SORACOM has no obligation to perform such
                        repair or replacement within 24 hours.
                    </li>
                    <li>
                        If the Subscriber reports an error, and it turns out that there are no
                        mistakes in SORACOM's systems or network or service, or the fault lies in
                        the Subscriber equipment or internal networks under, the Subscriber is
                        required to cover SORACOM's expenses related to such error detection.
                    </li>
                </ol>

                <h2>INTELLECTUAL PROPERTY</h2>
                <h3>Intellectual Property</h3>
                <p>
                    The patent, utility model right, design right, copyright, knowhow and any other
                    intellectual property, and the data of the demonstration experiment and any
                    other data related to the Soracom Mobile Service, the SORACOM System, the
                    SORACOM Information or the accessorial services thereof belong to SORACOM.
                    Nothing in this Agreement or the disclosure of information to the Subscriber by
                    SORACOM in relation to the Soracom Mobile Service, the SORACOM System or the
                    accessorial services thereof shall be construed, by implication, estoppel or
                    otherwise in any sense, as a license, granting or transferring SORACOM’s patent,
                    utility model right, design right, copyright, knowhow or any other intellectual
                    property to the Subscriber or any other third party.
                </p>

                <h2>DISCLAIMER OF WARRANTIES</h2>
                <h3>Disclaimer of Warranties</h3>
                <p>
                    THE SUBSCRIBER AGREES THAT THE SORACOM MOBILE SERVICE, THE SORACOM SYSTEM, THE
                    SORACOM INFORMATION AND THE ACCESSORIAL SERVICES THEREOF PROVIDED ACCORDING TO
                    THIS AGREEMENT SHALL BE PROVIDED ON AN “AS-IS” BASIS. TO THE EXTENT PERMITTED BY
                    LAW, SORACOM DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS OR
                    IMPLIED, WITH RESPECT TO THE SORACOM MOBILE SERVICE, THE SORACOM SYSTEM, THE
                    SORACOM INFORMATION OR THE ACCESSORIAL SERVICES THEREOF, INCLUDING, WITHOUT
                    LIMITATION, ANY WARRANTIES OF QUALITY, CONSISTENCY OR SECURITY OF SERVICE,
                    UNINTERRUPTED SERVICE, NON-MISTAKE, NON- INFRINGEMENT OF THIRD PARTY’S RIGHT,
                    MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSE.
                </p>

                <h2>INDEMNIFICATION</h2>
                <h3>Indemnification</h3>
                <p>
                    SORACOM and the Subscriber shall, except as otherwise provided, indemnify the
                    other party for damages as a result of its own breach of the Agreement.
                </p>

                <h3>Limitation of Liability</h3>
                <ol>
                    <li>
                        If the Soracom Mobile Service, the SORACOM System, the SORACOM Information
                        or the accessorial services thereof become unavailable due to any cause
                        imputable to a third party (including but not limited to telecommunication
                        carrier(s) or cloud provider(s)), SORACOM shall not bear any responsibility
                        in relation to such matter.
                    </li>
                    <li>
                        If the Soracom Mobile Service becomes completely unavailable due to a cause
                        imputable to SORACOM for a period of 24 hours or longer (the “Unavailable
                        Time”) commencing from the time when SORACOM recognizes the Soracom Mobile
                        Service has reached such status, upon the Subscriber’s request, SORACOM
                        shall refund the Service Fee according to the number of days obtained by
                        dividing the Unavailable Time by 24 (rounding down decimals) from the
                        Service Fee paid by such Subscriber, provided that, if the Subscriber does
                        not request within three months after the date on which the Subscriber
                        becomes aware of such Unavailable Time, SORACOM deems that such Subscriber
                        waives the right to request such reduction. OUR LIABILITY TO YOU FOR
                        FAILURES OF THE SORACOM MOBILE SERVICE IS LIMITED SOLELY TO THE REFUND SET
                        FORTH ABOVE.
                    </li>
                    <li>
                        IN ANY CASE WHERE SORACOM IS LIABLE TO COMPENSATE THE SUBSCRIBER FOR LOSS OR
                        DAMAGE, SORACOM’S LIABILITY SHALL NOT EXCEED THE SERVICE FEE ACTUALLY
                        RECEIVED BY SORACOM FROM THE SUBSCRIBER HEREUNDER IN THE THREE (3) MONTHS
                        IMMEDIATELY PRIOR TO THE EVENT CAUSING SUCH LOSS OR DAMAGE, AND IN NO EVENT
                        SHALL SORACOM HAVE ANY LIABILITY TO THE SUBSCRIBER UNDER OR IN CONNECTION
                        WITH THIS AGREEMENT FOR:

                        <ol>
                            <li>LOSS OF ACTUAL OR ANTICIPATED INCOME OR PROFITS;</li>
                            <li>LOSS OF GOODWILL OR REPUTATION;</li>
                            <li>LOSS OF ANTICIPATED COST SAVINGS;</li>
                            <li>LOSS OF DATA; OR</li>
                            <li>
                                ANY INDIRECT OR CONSEQUENTIAL LOSS OR DAMAGE OF ANY KIND HOWSOEVER
                                ARISING AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF
                                CONTRACT OR OTHERWISE, WHETHER OR NOT SUCH LOSS OR DAMAGE IS
                                FORESEEABLE, FORESEEN OR KNOWN.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Notwithstanding the provisions of the preceding paragraphs, if the Soracom
                        Mobile Service becomes unavailable due to a cause imputable to
                        telecommunication carrier or cloud provider, SORACOM will compensate the
                        Subscriber for damage (limited to actual and ordinary damage, and excluding
                        lost profit and indirect damages) up to the amount received from such
                        telecommunication carrier or cloud provider as compensation for such damage.
                    </li>
                    <li>
                        SORACOM shall not bear any responsibility for lost profits arising out of
                        the use of the Soracom Mobile Service, costs incurred in relation to repair
                        of any fault, request to repair any fault, or dealing with any inquiry in
                        relation to any fault in the Soracom Mobile Service, or any complaints or
                        claims from the Subscriber’s Customer or any other third party. The
                        Subscriber shall not in any circumstance claim such lost profits or costs
                        against SORACOM.
                    </li>
                    <li>
                        In the case where the Telecommunications Facility or system is repaired or
                        recovered, there is a possibility that information stored in the
                        Telecommunications Facility or system may be altered or lost. SORACOM shall
                        not bear any responsibility for damages arising from such alteration or
                        loss, provided, that, this shall not apply to any damage arising from
                        SORACOM’s willful misconduct or gross negligence.
                    </li>
                </ol>

                <h2>PROCESSING OF PERSONAL DATA</h2>
                <ol>
                    <li>
                        SORACOM collects and processes personal data about the Subscriber, including
                        name, Apple ID, e-mail, IP-address as well as data on data uses and billing
                        data in order to provide SORACOM’s service and other purposes such as
                        billing.
                    </li>

                    <li>
                        By entering into this Agreement with SORACOM the Subscriber explicitly
                        consent to SORACOM processing personal data about him/her.
                    </li>

                    <li>
                        When providing the Soracom Mobile Service as a communications services
                        provider, SORACOM will process any data processed for the purpose of the
                        conveyance of a communication on an electronic communications network or for
                        the billing thereof ("Traffic Data") as a data controller for the following
                        purposes: (i) to provide and maintain the services; (ii) to calculate the
                        Service Fee payable by the Subscriber, including other accounting, tax and
                        billing purposes; (iii) to identify, investigate and protect against fraud,
                        threats and unlawful or wrongful use of the services; (iv) for internal use
                        for development and improvement of the services; and / or (v) as required by
                        applicable law.
                    </li>

                    <li>
                        SORACOM may disclose Traffic Data: (i) if required by applicable law, court
                        order, or any other statutory or supervisory authority; or (ii) to SORACOM
                        affiliates or third parties that are lawfully sub-processing the Traffic
                        Data in order for SORACOM to deliver the services. SORACOM will anonymize or
                        delete Traffic Data when SORACOM no longer requires it for the purposes set
                        forth in Article 17(c).
                    </li>

                    <li>
                        The Subscriber can access additional information about the personal data
                        that is processed by SORACOM in the policy on SORACOM's website (the
                        "Privacy Policy"), for the Subscribers in Japan:
                        https://soracommobile.com/ja/terms/privacy/, and for the Subscribers in the
                        other countries: https://soracommobile.com/terms/privacy/. The Privacy
                        Policy contains information on the purposes, legal basis for processing,
                        recipients of personal data, data retention period and the rights of the
                        Subscriber.
                    </li>
                </ol>

                <h2>MISCELLANEOUS</h2>
                <h3>Posting this Agreement</h3>
                <p>SORACOM will post the latest General Terms on the App or SORACOM’s website.</p>

                <h3>Force Majeure</h3>
                <p>
                    No delay, failure or default in performance of any obligations in this Agreement
                    shall constitute a breach of this Agreement, to the extent that such failure to
                    perform, delay or default arises out of a cause beyond the control of and
                    without the negligence of the party otherwise chargeable with failure, delay or
                    default, including but not limited to action or inaction of governmental, civil
                    or military authorities, fire, strike, lockouts or other labor dispute, flood,
                    war, riots, earthquake, natural disaster, breakdown of public common carrier or
                    communications facilities; computer malfunction. This Section shall in no way
                    limit the right of either party to this Agreement to make any claim against
                    third parties for any damages suffered due to the said causes.
                </p>

                <h3>Terminating and rejecting relations with Anti-social Forces</h3>
                <p>
                    Subscriber acknowledges and agrees that, since SORACOM affiliates are Japanese
                    companies, SORACOM must comply with the relevant rules, regulations and
                    standards which purport to eliminate any relationship with “Anti-Social Forces”
                    (meaning violent gangs, yakuza, mafia or other violent, blackmailing, or
                    intimidatory groups or people who for political, religious or other ideological
                    or economic purposes engage in anti-social activities or behavior including, but
                    not limited to “Organized Crime Groups” or “Organized Crime Group Members” (as
                    respectively defined in Article 2, items (ii) and (vi) of the Prevention of
                    Unjust Acts by Organized Crime Group Members Act of Japan. The same shall apply
                    hereafter.). As a part of such compliance, Subscriber shall ensure that at all
                    times it and its officers, employees and any shareholders in the Subscriber:
                </p>

                <ol>
                    <li>
                        do not and will not have any relationship or connection with any Anti-Social
                        Forces;
                    </li>
                    <li>
                        are not and will not become involved in any financial dealings with or
                        provide any financial assistance to any Anti-Social Forces for any reason
                        whatsoever; and/or
                    </li>
                    <li>
                        do not and will not employ, hire or otherwise engage any person who is a
                        member of any Anti-Social Force or appoint such person to any directorship
                        or officer position.
                    </li>
                </ol>
                <h3>Severability</h3>
                <p>
                    If any provision in this Agreement is found to be invalid or unenforceable, then
                    the meaning of such provision shall be construed, to the extent feasible, so as
                    to render the provision enforceable, and if not feasible.
                </p>

                <h3>Dispute Resolution</h3>
                <p>
                    Any dispute arising out of or in connection with this Agreement, including any
                    question regarding its existence, validity or termination, shall be referred to
                    and finally resolved by arbitration in the London Court of International
                    Arbitration (the “LCIA”). LCIA will apply the rules in force when the
                    application for arbitration is submitted. SORACOM and the Subscriber will
                    appoint 1 arbitrator each. LCIA will appoint 1 additional arbitrator who will be
                    chairman of the arbitration tribunal. If either SORACOM or the Subscriber fails
                    to appoint an arbitrator no later than 30 days after submitting an application
                    for arbitration or receiving notice of arbitration, the LCIA will also appoint
                    that arbitrator. The arbitration proceedings will take place in London, United
                    Kingdom, and the language of the proceedings will be English.
                </p>

                <h3>Governing law</h3>
                <p>
                    The General Terms and the Agreement shall be governed by and interpreted in
                    accordance with the laws of England and Wales. However, the conflict of laws
                    rules must be disregarded to the extent that such rules are non-mandatory.
                </p>

                <h2 class="no-increment">Schedule 1</h2>

                <h3 class="no-increment">Service Fee</h3>

                <ol>
                    <li>
                        Data volume, duration and price of Soracom Mobile Service for each region
                        (the countries or scope of each region are specified in the App) are
                        described in the table below. Where the data volume and the duration still
                        remains, and the Subscriber makes an additional purchase of a plan for the
                        same region, the data volume and the duration of the plan will be added to
                        the remaining data volume and the duration (top-up). HOWEVER, SUBSCRIBER
                        SHALL LOSE THE RIGHT TO USE THE SORACOM MOBILE SERVICE AFTER A LAPSE OF
                        6-MONTH PERIOD COMMENCING ON/WITH THE EFFECTIVE DATE (THE DAY SUBSCRIBER
                        INITIALLY PURCHASES THE SORACOM MOBILE SERVICE) REGARDLESS WHETHER THE DATA
                        VOLUME AND/OR THE DURATION REMAINS OR NOT. PLEASE MAKE SURE TO USE UP THE
                        DATA VOLUME WITHIN THE 6 MONTHS.
                    </li>
                    <li>
                        For the Subscribers who purchased the Soracom Mobile Service via App Store
                        UK, the price below includes VAT.
                    </li>
                </ol>

                <h3 class="no-increment">Regional Plan</h3>

                <h4>Europe</h4>

                <table>
                    <thead>
                        <tr>
                            <th>Data volume</th>
                            <th>Duration</th>
                            <th>Price (US Dollar)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                5.89
                            </td>
                        </tr>
                        <tr>
                            <td>3GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                14.99
                            </td>
                        </tr>
                        <tr>
                            <td>5GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                23.99
                            </td>
                        </tr>
                        <tr>
                            <td>10GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                42.99
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h4>North America</h4>

                <table>
                    <thead>
                        <tr>
                            <th>Data volume</th>
                            <th>Duration</th>
                            <th>Price (US Dollar)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                10.99
                            </td>
                        </tr>
                        <tr>
                            <td>3GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                28.99
                            </td>
                        </tr>
                        <tr>
                            <td>5GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                44.99
                            </td>
                        </tr>
                        <tr>
                            <td>10GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                97.99
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h4>Oceania</h4>

                <table>
                    <thead>
                        <tr>
                            <th>Data volume</th>
                            <th>Duration</th>
                            <th>Price (US Dollar)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                12.99
                            </td>
                        </tr>
                        <tr>
                            <td>3GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                32.99
                            </td>
                        </tr>
                        <tr>
                            <td>5GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                52.99
                            </td>
                        </tr>
                        <tr>
                            <td>10GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                97.99
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h4>Asia Pacific (11 countries)</h4>

                <p>
                    Available in USA, Vietnam, Australia, Taiwan, Hong Kong, Singapore, Indonesia,
                    Macau, Cambodia, Laos, Sri Lanka
                </p>

                <table>
                    <thead>
                        <tr>
                            <th>Data volume</th>
                            <th>Duration</th>
                            <th>Price (US Dollar)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                6.99
                            </td>
                        </tr>
                        <tr>
                            <td>3GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                19.99
                            </td>
                        </tr>
                        <tr>
                            <td>5GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                30.99
                            </td>
                        </tr>
                        <tr>
                            <td>10GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                54.99
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h4>Asia Pacific (15 countries)</h4>

                <p>
                    Available in USA, Vietnam, Australia, Taiwan, Hong Kong, Singapore, Indonesia,
                    Macau, Cambodia, Laos, Sri Lanka, Thailand, South Korea, India, Japan
                </p>

                <table>
                    <thead>
                        <tr>
                            <th>Data volume</th>
                            <th>Duration</th>
                            <th>Price (US Dollar)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                10.99
                            </td>
                        </tr>
                        <tr>
                            <td>3GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                31.99
                            </td>
                        </tr>
                        <tr>
                            <td>5GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                46.99
                            </td>
                        </tr>
                        <tr>
                            <td>10GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                89.99
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h4>Asia Pacific (18 countries)</h4>

                <p>
                    Available in USA, Vietnam, Australia, Taiwan, Hong Kong, Singapore, Indonesia,
                    Macau, Cambodia, Laos, Sri Lanka, Thailand, South Korea, India, Japan, Mainland
                    China, New Zealand, Bangladesh
                </p>

                <table>
                    <thead>
                        <tr>
                            <th>Data volume</th>
                            <th>Duration</th>
                            <th>Price (US Dollar)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                17.99
                            </td>
                        </tr>
                        <tr>
                            <td>3GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                49.99
                            </td>
                        </tr>
                        <tr>
                            <td>5GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                74.99
                            </td>
                        </tr>
                        <tr>
                            <td>10GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                145.99
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h4>Single-country Plan (Japan)</h4>

                <table>
                    <thead>
                        <tr>
                            <th>Data volume</th>
                            <th>Duration</th>
                            <th>Price (US Dollar)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                6.89
                            </td>
                        </tr>
                        <tr>
                            <td>3GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                17.79
                            </td>
                        </tr>
                        <tr>
                            <td>5GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                28.79
                            </td>
                        </tr>
                        <tr>
                            <td>10GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                54.99
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h4>
                    Single-country Plan (Available in one of the following countries: either USA,
                    Vietnam, Australia, Taiwan, Hong Kong, Singapore, Indonesia, Macau, Cambodia,
                    Laos or Sri Lanka)
                </h4>

                <table>
                    <thead>
                        <tr>
                            <th>Data volume</th>
                            <th>Duration</th>
                            <th>Price (US Dollar)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                5.89
                            </td>
                        </tr>
                        <tr>
                            <td>3GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                16.49
                            </td>
                        </tr>
                        <tr>
                            <td>5GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                26.99
                            </td>
                        </tr>
                        <tr>
                            <td>10GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                52.99
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h4>
                    Single-country Plan (Available in one of the following countries: either
                    Thailand, South Korea or India)
                </h4>

                <table>
                    <thead>
                        <tr>
                            <th>Data volume</th>
                            <th>Duration</th>
                            <th>Price (US Dollar)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                9.49
                            </td>
                        </tr>
                        <tr>
                            <td>3GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                27.49
                            </td>
                        </tr>
                        <tr>
                            <td>5GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                45.49
                            </td>
                        </tr>
                        <tr>
                            <td>10GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                87.99
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h4>
                    Single-country Plan (Available in one of the following countries: either
                    Mainland China or Bangladesh)
                </h4>

                <table>
                    <thead>
                        <tr>
                            <th>Data volume</th>
                            <th>Duration</th>
                            <th>Price (US Dollar)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                14.49
                            </td>
                        </tr>
                        <tr>
                            <td>3GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                43.49
                            </td>
                        </tr>
                        <tr>
                            <td>5GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                69.99
                            </td>
                        </tr>
                        <tr>
                            <td>10GB</td>
                            <td>30days (720 hours)</td>
                            <td>
                                139.99
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h2 class="no-increment">Schedule 2</h2>

                <p>
                    Model withdrawal form for Subscribers that are consumers (i.e. a natural person
                    acting for purposes outside of his/her trade or profession)
                </p>

                <p>
                    (complete and return this form only if you wish to withdraw from the contract)
                </p>

                <ul>
                    <li>
                        To SORACOM CORPORATION, LTD, Covent Garden, London, United Kingdom, WC2B
                        5AH, registered under company number 12311887:
                    </li>

                    <li>
                        I/We (*) hereby give notice that I/We (*) withdraw from my/our (*) contract
                        of sale for the following provision of the following service,
                    </li>

                    <li>Ordered on (*)/received on (*),</li>

                    <li>Name of consumer(s),</li>

                    <li>Address of consumer(s),</li>

                    <li>Signature of consumer(s) (only if this form is notified on paper),</li>

                    <li>Date</li>
                </ul>

                <p>(*) Delete as appropriate.</p>`
