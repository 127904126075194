<template>
    <div>
        <div class="container">
            <h1 class="page-title">{{ $t("title") }}</h1>
        </div>

        <section class="section">
            <div class="container terms" v-html="$t('terms')"></div>
        </section>
    </div>
</template>

<script>
export default {
    components: {},
    meta() {
        return this.$generateMeta({
            title: this.$i18n.t("meta.title"),
            description: this.$i18n.t("meta.description"),
        })
    },

    i18n: {
        messages: {
            en: {
                meta: {
                    title: "Terms of Use",
                    description: "Terms of Use",
                },
                title: "Terms of Use",

                terms: require("@/data/terms/en"),
            },
            ja: {
                terms: require("@/data/terms/ja"),
                meta: {
                    title: "利用規約",
                    description: "ソラコム モバイルの利用規約です",
                },
                title: "利用規約",
            },
        },
    },
}
</script>

<style>
.container.terms {
    counter-reset: h2counter;
}
.terms h2 {
    margin-top: 4em;
    counter-reset: h3counter;
}
.terms h2::before {
    counter-increment: h2counter;
    content: counter(h2counter) ". ";
}
.terms h3 {
    margin-top: 2em;
}
.terms h3::before {
    counter-increment: h3counter;
    content: counter(h2counter) "." counter(h3counter) " ";
}

.terms ol {
    padding-left: 1.5em;
}

.terms h4 {
    margin-top: 2em;
}

.terms .no-increment::before {
    content: "";
}
</style>
